<template>
  <section class="quiz-question-type-virus-memo">
    <v-row no-gutters>
      <v-col cols="12" v-html="question.title" class="quiz-question"></v-col>
      <v-col cols="12" class="quiz-question__info quiz-question__info__regular">Zaznacz genotypy - poprawne zostaną oznaczone na zielono</v-col>
      <v-col cols="12">
        <div id="select-area">
          <div
              v-for="(element, elementKey) in selectOptions"
              class="virus"
              :style="{ left: element.currentPosition.x, top: element.currentPosition.y }"
              :key="elementKey"
              v-bind:class="{ 'answer-correct': element.correctGlow, 'answer-incorrect': element.incorrectGlow }"
              @click="checkAnswer(elementKey)">
            <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
            <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
              {{ element.virusId }}
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="text-center">
        <button-primary v-if="validAnswers" title="Dalej" @click="nextQuestion" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: "QuizQuestionTypePersonComponent",
  props: {
    question: Object,
    debug: Boolean,
  },
  components: {
    ButtonPrimary,
  },
  emits: ["next-question", "reference-number"],
  expose: ["cleanUp"],
  data() {
    return {
      positionIteration: 0,
      selectOptions: [...this.question.answers],
      validAnswers: false,
      moveVirusInterval: null,
    };
  },
  created() {
    this.moveViruses();
    this.moveVirusInterval = setInterval(this.moveViruses, 1000);
    this.$emit("reference-number", this.question.referenceNumber);
  },
  unmounted() {
    clearInterval(this.moveVirusInterval);
  },
  methods: {
    cleanUp() {
      this.positionIteration = 0;
      this.validAnswers = false;
      for (let i in this.selectOptions) {
        this.selectOptions[i].incorrectLabel = false;
        this.selectOptions[i].correctGlow = false;
        this.selectOptions[i].incorrectGlow = false;
        this.selectOptions[i].correctLabel = false;
      }
    },

    moveViruses() {
      for (let i in this.selectOptions) {
        if (this.selectOptions[i].onMove !== true) {
          let positionIndex = this.positionIteration % this.selectOptions[i].positions.length;
          this.selectOptions[i].currentPosition.x = this.selectOptions[i].positions[positionIndex].x;
          this.selectOptions[i].currentPosition.y = this.selectOptions[i].positions[positionIndex].y;
        }
      }

      this.positionIteration += 1;
    },

    nextQuestion() {
      this.$emit("next-question");
    },

    checkAnswer(optionKey) {
      if (this.selectOptions[optionKey].isCorrect) {
        this.selectOptions[optionKey].correctGlow = true;
        this.selectOptions[optionKey].correctLabel = true;
      } else {
        this.selectOptions[optionKey].incorrectGlow = true;
        this.selectOptions[optionKey].incorrectLabel = true;
        setTimeout(() => {
          this.selectOptions[optionKey].incorrectGlow = false;
          this.selectOptions[optionKey].incorrectLabel = false;
        }, 3000);
      }
      let answerCount = 0;
      for (let i in this.selectOptions) {
        if (this.selectOptions[i].correctGlow === true) {
          answerCount++;
        }
      }
      if (answerCount >= 2) {
        this.validAnswers = true;
      }
    },
  },
};
</script>

<style lang="scss">


.quiz-question-type-virus-memo {
  .quiz-question {
    font-size: 1.75rem;
  }
  
  .virus {
    position: relative;
    width: 8em;
    transition: top 1s, left 1s;
    transition-timing-function: linear;
    margin-top: -0.615em;

    img {
      width: 100%;
      height: auto;
    }

    p {
      position: absolute;
      top: 60%;
      left: 65%;
      color: $colorDarkGreen;
      background-color: #ffffff;
      border-radius: 0.25rem;
      border-left: 0.125rem solid $colorYellow;
      border-right: 0.125rem solid $colorYellow;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding-top: .125rem;
      font-family: "Invention-Bold";
      width: 2rem;
      text-align: center;
      -webkit-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    }
  }

  #select-area {
    position: relative;
    height: 63vh;
    margin-bottom: 0.5rem;

    .virus {
      position: absolute;

      &.answer-correct {
        -webkit-filter: drop-shadow(0 0 2rem $colorGreen);
        filter: drop-shadow(0 0 1rem $colorGreen);
        background: radial-gradient(circle at center, $colorGreen 16%, transparent 56%);
      }

      &.answer-incorrect {
        -webkit-filter: drop-shadow(0 0 2rem $colorRed);
        filter: drop-shadow(0 0 1rem $colorRed);
        background: radial-gradient(circle at center, $colorRed 16%, transparent 56%);
      }

      p {
        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: 0.125rem solid $colorForestGreen;
          border-right: 0.125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: 0.125rem solid $colorDarkRed;
          border-right: 0.125rem solid $colorDarkRed;
        }
      }
    }
  }
}
</style>
