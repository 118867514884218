<template>
  <section class="quiz-question-type-person">
    <v-row no-gutters>
      <v-col cols="12" v-html="question.title" class="quiz-question"></v-col>
      <v-col cols="12" class="quiz-question__info" :class="question.extraClass">
        <div v-if="showCorrectAnswerMessage" class="text-uppercase quiz-question__info__success">poprawna odpowiedź</div>
        <div v-if="showWrongAnswerMessage" class="text-uppercase quiz-question__info__danger">
          błędna odpowiedź
          <span class="text-lowercase">poniżej&nbsp;prawidłowe rozwiązanie:</span>
        </div>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12" class="question-image">
            <div class="question-background" :style="{ 'background-image': 'url(\'/img/virus/' + question.imgPrefixPath + '/background.png' + '\')' }">
              <div
                  v-for="(area, areaKey) in areas"
                  class="question-area"
                  :style="{ left: area.position.x, top: area.position.y, width: area.width, height: area.height, 'z-index': area.index }"
                  :key="areaKey"
                  v-bind:class="{ 'question-area__debug': debug && !area.isCorrect, 'question-area__debug-selected': debug && area.selected, 'question-area__debug-valid': debug && area.isCorrect, }"
                  @click="selectOption(areaKey)">
                <img v-if="area.selected && !showWrongAnswerMessage" :src="'/img/virus/' + question.imgPrefixPath + '/ico.png'"/>
              </div>
            </div>
            <div
                class="question-answer"
                v-bind:class="{ hidden: !showAnswer }"
                :style="{
                                'background-image': 'url(\'/img/virus/' + question.imgPrefixPath + '/answer.png' + '\')',
                                left: question.answerPosition.x,
                                top: question.answerPosition.y,
                                width: question.answerPosition.width,
                                height: question.answerPosition.height,
                            }"></div>
          </v-col>
          <v-col cols="12" v-if="showCheck && !(showCorrectAnswerMessage || showWrongAnswerMessage)">
            <button-primary title="Sprawdź" @click="validateAnswers" :icon="require('@/assets/img/icon/play-ico.png')"/>
          </v-col>
          <v-col cols="12" v-if="(showWrongAnswerMessage && retryTimes >= 1) || showCorrectAnswerMessage">
            <button-primary title="Dalej" @click="nextQuestion" :icon="require('@/assets/img/icon/play-ico.png')"/>
          </v-col>
          <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes === 0">
            <button-primary title="Dalej" @click="showDialog = true" :icon="require('@/assets/img/icon/play-ico.png')"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>

  <dialog-answer :dialog-state="showDialog" @continue="nextQuestion" @retry="retry" :is-success="showCorrectAnswerMessage"/>
</template>

<script>
import DialogAnswer from "@/components/Content/DialogAnswerComponent";
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: "QuizQuestionTypePersonComponent",
  props: {
    question: Object,
    debug: Boolean,
  },
  components: {
    DialogAnswer,
    ButtonPrimary,
  },
  emits: ["next-question", "reference-number"],
  expose: ["cleanUp"],
  data() {
    return {
      showDialog: false,
      retryTimes: 0,
      areas: [...this.question.areas],
      showAnswer: false,
      showWrongAnswerMessage: false,
      showCorrectAnswerMessage: false,
      showCheck: false,
    };
  },
  created() {
    this.$emit("reference-number", this.question.referenceNumber);
  },
  methods: {
    cleanUp() {
      this.retry();
      this.retryTimes = 0;
    },

    retry() {
      this.retryTimes += 1;
      this.showDialog = false;
      this.showAnswer = false;
      this.areas = [...this.question.areas];
      for (let i in this.areas) {
        this.areas[i].selected = false;
      }
      this.showWrongAnswerMessage = false;
      this.showCorrectAnswerMessage = false;
      this.showCheck = false;
    },

    nextQuestion() {
      if (this.showCorrectAnswerMessage === true && this.showDialog === true) {
        this.showDialog = false;
      } else {
        this.$emit("next-question");
      }
    },

    selectOption(index) {
      if (this.areas[index].selected) {
        this.areas[index].selected = false;
      } else {
        this.areas[index].selected = true;
      }

      this.controlCheckButton();
    },

    controlCheckButton() {
      if (this.showCorrectAnswerMessage || this.showWrongAnswerMessage) {
        return
      }
      let showButton = false;
      for (let i in this.areas) {
        if (this.areas[i].selected) {
          showButton = true;
        }
      }

      this.showCheck = showButton;
    },

    validateAnswers() {
      this.showCheck = false;
      let isValid = true;

      for (let i in this.areas) {
        let isSelected = this.areas[i].selected;
        if (typeof isSelected === "undefined") {
          isSelected = false;
        }
        if (isSelected !== this.areas[i].isCorrect) {
          isValid = false;
        }
      }

      this.showAnswer = true;
      if (isValid === true) {
        this.showCorrectAnswerMessage = true;
        this.showDialog = true;
      } else {
        this.showWrongAnswerMessage = true;
      }
    },
  },
};
</script>

<style lang="scss">


.quiz-question-type-person .quiz-person-type-multi-person {
  width: auto;
  text-align: center;
  position: static;
  margin-bottom: 0;
  margin-top: -2rem;
}


.quiz-question-type-person {
  .quiz-question {
    margin-bottom: 2.5rem;
    font-size: 1.75rem;
  }

  .question-image {
    position: relative;

    .question-background {
      width: 100%;
      height: 72vh;

      .question-area {
        position: absolute;

        img {
          width: 3.625rem;
          height: auto;
        }

        &__debug {
          background-color: rgb(176, 12, 12, 0.2);
        }

        &__debug-valid {
          background-color: rgba(20, 176, 12, 0.2);
        }

        &__debug-selected {
          background-color: rgba(37, 176, 12, 0.2);
        }
      }
    }

    .question-answer {
      z-index: 1;
      transition: opacity 0.3s;

      &.hidden {
        opacity: 0;
      }
    }

    .question-background,
    .question-answer {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }
}
</style>
