<template>
  <button class="button-primary text-uppercase">
    {{ title }}
    <img v-if="icon" :src="icon"/>
  </button>
</template>
<script>
export default {
  name: "ButtonPrimaryComponent",
  props: {
    title: String,
    icon: String,
  },
};
</script>

<style lang="scss">
.button-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.25rem;
  min-width: 11rem;
  padding: 0.25rem 0.75rem 0 0.75rem;
  font-family: "Invention-Bold";
  font-size: 1.25rem;
  background-color: #ffffff;
  color: $colorDarkGreen;
  border-radius: 0.5rem;
  border-left: 0.25rem solid $colorYellow;
  border-right: 0.25rem solid $colorYellow;
  -webkit-box-shadow: 0px 0px 25px 2px rgb(24 24 27 / 28%);
  -moz-box-shadow: 0px 0px 25px 2px rgb(24 24 27 / 28%);
  box-shadow: 0px 0px 25px 2px rgb(24 24 27 / 28%);
  position: absolute;
  bottom: 1vh;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);

  img {
    margin-left: 1rem;
    height: 1.5rem;
    margin-top: -0.25rem;
  }
}
</style>
