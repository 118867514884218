<template>
  <section class="quiz-question-type-virus-select">
    <v-row no-gutters class="quiz-question__box align-content-start">
      <v-col cols="12" v-html="question.title" class="quiz-question"></v-col>
      <v-col cols="12" class="quiz-question__info">
        <div class="quiz-question__info__regular" v-if="answers.length < 5">Przeciągnij wirusa do pola wyboru</div>
        <div class="quiz-question__info__success text-uppercase" v-if="showCorrectAnswerMessage">poprawna odpowiedź</div>
        <div class="quiz-question__info__danger text-uppercase" v-if="showWrongAnswerMessage">błędna odpowiedź</div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <draggable
            v-model="selectOptions"
            :group="{ name: 'selectedViruses', pull: true, put: false }"
            itemKey="virusId"
            handle=".virus"
            :sort="false"
            id="select-area"
            :onStart="startMoveVirusManual"
            :onEnd="stopMoveVirusManual"
            :disabled="answers.length >= 5">
          <template #item="{ element, index }">
            <div
                class="virus"
                :style="{ left: element.currentPosition.x, top: element.currentPosition.y }"
                :key="index"
                v-bind:class="{ 'answer-correct': element.correctGlow, 'answer-incorrect': element.incorrectGlow }">
              <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
              <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
                {{ element.virusId }}
              </p>
            </div>
          </template>
        </draggable>
      </v-col>
      <v-col cols="12" class="answer-box__desc">
        <span v-if="!(showCorrectAnswerMessage || showWrongAnswerMessage)">Kliknij w wirusa, aby usunąć go z pola wyboru</span>
      </v-col>
      <v-col cols="12">
        <draggable v-model="answers" :group="{ name: 'selectedViruses', pull: false, put: true }" itemKey="virusId" handle=".virus" :sort="false" class="answer-box"
                   :fallbackTolerance="100">
          <template #item="{ element, index }">
            <div class="virus" @click="removeVirusFromAnswers(index)">
              <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
              <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
                {{ element.virusId }}
              </p>
            </div>
          </template>
        </draggable>
      </v-col>
      <v-col cols="12" v-if="answers.length >= 5 && !(showCorrectAnswerMessage || showWrongAnswerMessage)">
        <button-primary title="Sprawdź" @click="validateAnswers" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes >= 1">
        <button-primary title="Dalej" @click="nextQuestion" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes === 0">
        <button-primary title="Dalej" @click="showDialog = true" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
    </v-row>
    <dialog-answer :dialog-state="showDialog" @continue="nextQuestion" @retry="retry" :is-success="showCorrectAnswerMessage"/>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import DialogAnswer from "@/components/Content/DialogAnswerComponent";
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: "QuizQuestionTypeVirusSelectComponent",
  props: {
    question: Object,
    debug: Boolean,
  },
  components: {
    draggable,
    DialogAnswer,
    ButtonPrimary,
  },
  emits: ["next-question", "reference-number"],
  expose: ["cleanUp"],
  data() {
    return {
      positionIteration: 0,
      selectOptions: [...this.question.answers],
      answers: [],
      showDialog: false,
      moveVirusInterval: null,
      retryTimes: 0,
      showWrongAnswerMessage: false,
      showCorrectAnswerMessage: false,
    };
  },
  created() {
    this.moveViruses();
    this.moveVirusInterval = setInterval(this.moveViruses, 1000);
    this.$emit("reference-number", this.question.referenceNumber);
  },
  unmounted() {
    clearInterval(this.moveVirusInterval);
  },
  methods: {
    cleanUp() {
      this.retry();
      this.retryTimes = 0;
    },

    moveViruses() {
      for (let i in this.selectOptions) {
        if (this.selectOptions[i].onMove !== true) {
          let positionIndex = this.positionIteration % this.selectOptions[i].positions.length;
          this.selectOptions[i].currentPosition.x = this.selectOptions[i].positions[positionIndex].x;
          this.selectOptions[i].currentPosition.y = this.selectOptions[i].positions[positionIndex].y;
        }
      }

      this.positionIteration += 1;
    },

    startMoveVirusManual(evt) {
      this.selectOptions[evt.oldIndex].onMove = true;
    },

    stopMoveVirusManual(evt) {
      if (evt.to.id === "select-area") {
        this.selectOptions[evt.oldIndex].onMove = false;
      }
    },

    nextQuestion() {
      this.$emit("next-question");
    },

    retry() {
      this.retryTimes += 1;
      this.selectOptions = [...this.question.answers];
      for (let i in this.selectOptions) {
        this.selectOptions[i].onMove = false;
        this.selectOptions[i].incorrectLabel = false;
        this.selectOptions[i].correctGlow = false;
        this.selectOptions[i].incorrectGlow = false;
        this.selectOptions[i].correctLabel = false;
      }
      this.answers = [];
      this.showDialog = false;
      this.showWrongAnswerMessage = false;
      this.showCorrectAnswerMessage = false;
    },

    validateAnswers() {
      let isValid = true;
      for (let i in this.answers) {
        if (this.answers[i].isCorrect === true) {
          this.answers[i].correctLabel = true;
        } else {
          isValid = false;
          this.answers[i].incorrectLabel = true;
        }
      }

      if (isValid === false) {
        this.showWrongAnswerMessage = true;
        for (let i in this.selectOptions) {
          if (this.selectOptions[i].isCorrect === true) {
            this.selectOptions[i].correctGlow = true;
            this.selectOptions[i].correctLabel = true;
          } else {
            this.selectOptions[i].incorrectGlow = true;
            this.selectOptions[i].incorrectLabel = true;
          }
        }
      } else {
        this.showCorrectAnswerMessage = true;
        this.showDialog = true;
      }
    },

    removeVirusFromAnswers(answerIndex) {
      if (this.showCorrectAnswerMessage || this.showWrongAnswerMessage) {
        return
      }
      this.answers[answerIndex].onMove = false;
      this.selectOptions.push(this.answers[answerIndex]);
      this.answers.splice(answerIndex, 1);
    },
  },
};
</script>

<style lang="scss">


.quiz-question-type-virus-select {
  .virus {
    width: 8em;
    transition: top 1s, left 1s;
    transition-timing-function: linear;
    margin-top: -0.615em;

    img {
      width: 100%;
      height: auto;
    }

    p {
      position: absolute;
      top: 60%;
      left: 65%;
      color: $colorDarkGreen;
      background-color: #ffffff;
      border-radius: 0.25rem;
      border-left: 0.125rem solid $colorYellow;
      border-right: 0.125rem solid $colorYellow;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding-top: .125rem;
      font-family: "Invention-Bold";
      width: 2rem;
      text-align: center;
      -webkit-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    }
  }

  #select-area {
    position: relative;
    height: 41vh;
    margin-bottom: 7vh;

    .virus {
      position: absolute;

      &.answer-correct {
        -webkit-filter: drop-shadow(0 0 2rem $colorGreen);
        filter: drop-shadow(0 0 1rem $colorGreen);
        background: radial-gradient(circle at center, $colorGreen 16%, transparent 56%);
      }

      &.answer-incorrect {
        -webkit-filter: drop-shadow(0 0 2rem $colorRed);
        filter: drop-shadow(0 0 1rem $colorRed);
        background: radial-gradient(circle at center, $colorRed 16%, transparent 56%);
      }

      p {
        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: 0.125rem solid $colorForestGreen;
          border-right: 0.125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: 0.125rem solid $colorDarkRed;
          border-right: 0.125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-ghost {
      opacity: 0;
    }

    .sortable-drag {
      opacity: 1 !important;
    }
  }

  .answer-box {
    margin: 0.125em auto;
    height: 7rem;
    width: 47rem;
    padding-left: 2.75rem;
    border-radius: 1.5em;
    border-left: 0.5em solid $colorYellow;
    border-right: 0.5em solid $colorYellow;
    background-color: white;
    -webkit-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);

    &__desc {
      font-family: "Invention-Italic";
      font-size: 1em;
      color: $colorYellow;
      position: absolute;
      top: 69vh;
    }

    .virus {
      float: left;
      position: relative;

      p {
        color: $colorDarkGreen;

        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: 0.125rem solid $colorForestGreen;
          border-right: 0.125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: 0.125rem solid $colorDarkRed;
          border-right: 0.125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-drag,
    .sortable-ghost {
      opacity: 0 !important;
    }
  }
}
</style>
