<template>
  <section class="quiz-question-type-virus-order">
    <v-row no-gutters v-if="showAnswer">
      <v-col cols="12" class="quiz-question text-uppercase">Twoja odpowiedź</v-col>
      <v-col cols="12" class="quiz-question__info">
        <div class="quiz-question__info__success text-uppercase" v-if="showCorrectAnswerMessage">
          poprawna odpowiedź
        </div>
        <div class="quiz-question__info__danger text-uppercase" v-if="showWrongAnswerMessage">
          błędna odpowiedź
        </div>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters class="justify-center quiz-question-type-virus-order__answer-box-section">
          <v-col cols="auto" class="answer-box answer-box-number">
            <div class="virus">
              <img
                  :src="'/img/virus/' + question.imgPrefixPath + '/' + answerFirst[0].virusId + '.png'"
                  :alt="answerFirst[0].imageAlt"
              />
              <p
                  v-bind:class="{ 'answer-correct': answerFirst[0].correctLabel, 'answer-incorrect': answerFirst[0].incorrectLabel }"
              >
                {{ answerFirst[0].virusId }}
              </p>
            </div>
          </v-col>
          <v-col cols="auto" class="answer-box answer-box-number">
            <div class="virus">
              <img
                  :src="'/img/virus/' + question.imgPrefixPath + '/' + answerSecond[0].virusId + '.png'"
                  :alt="answerSecond[0].imageAlt"
              />
              <p
                  v-bind:class="{ 'answer-correct': answerSecond[0].correctLabel, 'answer-incorrect': answerSecond[0].incorrectLabel }"
              >
                {{ answerSecond[0].virusId }}
              </p>
            </div>
          </v-col>
          <v-col cols="auto" class="answer-box answer-box-number">
            <div class="virus">
              <img
                  :src="'/img/virus/' + question.imgPrefixPath + '/' + answerThird[0].virusId + '.png'"
                  :alt="answerThird[0].imageAlt"
              />
              <p
                  v-bind:class="{ 'answer-correct': answerThird[0].correctLabel, 'answer-incorrect': answerThird[0].incorrectLabel }"
              >
                {{ answerThird[0].virusId }}
              </p>
            </div>
          </v-col>
          <v-col cols="auto" class="answer-box answer-box-number">
            <div class="virus">
              <img
                  :src="'/img/virus/' + question.imgPrefixPath + '/' + answerFourth[0].virusId + '.png'"
                  :alt="answerFourth[0].imageAlt"
              />
              <p
                  v-bind:class="{ 'answer-correct': answerFourth[0].correctLabel, 'answer-incorrect': answerFourth[0].incorrectLabel }"
              >
                {{ answerFourth[0].virusId }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto" class="quiz-question-type-virus-order__answer-table-box">
        <img :src="'/img/virus/' + question.imgPrefixPath + '/table-answer.png'" class="quiz-question-type-virus-order__answer-table">
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes >= 1">
        <button-primary title="Dalej" @click="nextQuestion" :icon="require('@/assets/img/icon/play-ico.png')" />
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes === 0">
        <button-primary title="Dalej" @click="showDialog = true" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
    </v-row>
    <v-row no-gutters v-if="!showAnswer">
      <v-col cols="12" v-html="question.title" class="quiz-question"></v-col>

      <v-col cols="12" class="quiz-question__info">
        <div class="quiz-question__info__regular" v-if="answerFirst.length < 1 || answerSecond.length < 1 || answerThird.length < 1 || answerFourth.length < 1">
          Przeciągnij wirusa do pola wyboru
        </div>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <draggable
                v-model="selectOptions"
                :group="{ name: 'selectedViruses', pull: true, put: false }"
                itemKey="virusId"
                handle=".virus"
                :sort="false"
                id="select-area"
                :onStart="startMoveVirusManual"
                :onEnd="stopMoveVirusManual"
                :disabled="answerFirst.length > 0 && answerSecond.length > 0 && answerThird.length > 0 && answerFourth.length > 0"
            >
              <template #item="{ element, index }">
                <div
                    class="virus"
                    :style="{ left: element.currentPosition.x, top: element.currentPosition.y }"
                    :key="index"
                    v-bind:class="{ 'answer-correct': element.correctGlow, 'answer-incorrect': element.incorrectGlow}"
                >
                  <img
                      :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'"
                      :alt="element.imageAlt"
                  />
                  <p
                      v-bind:class="{ 'answer-correct': element.correctLabel }"
                  >
                    {{ element.virusId }}
                  </p>
                </div>
              </template>
            </draggable>
          </v-col>

          <v-col cols="12" class="answer-box__desc">
            <span v-if="!(showCorrectAnswerMessage || showWrongAnswerMessage)">Kliknij w wirusa, aby usunąć go z pola wyboru</span>
          </v-col>

          <v-col cols="12">
            <v-row justify="center">
              <v-col cols="auto" class="answer-box-number-main">
                <draggable
                    v-model="answerFirst"
                    :group="answerFirstGroup"
                    itemKey="virusId"
                    handle=".virus"
                    :sort="false"
                    class="answer-box"
                    :fallbackTolerance="100"
                >
                  <template #item="{ element, index }">
                    <div class="virus" @click="removeVirusFromAnswers(answerFirst, index, answerFirstGroup)">
                      <img
                          :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'"
                          :alt="element.imageAlt"
                      />
                      <p
                          v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }"
                      >
                        {{ element.virusId }}
                      </p>
                    </div>
                  </template>
                </draggable>
              </v-col>
              <v-col cols="auto" class="answer-box-number-main">
                <draggable
                    v-model="answerSecond"
                    :group="answerSecondGroup"
                    itemKey="virusId"
                    handle=".virus"
                    :sort="false"
                    class="answer-box"
                    :fallbackTolerance="100"
                >
                  <template #item="{ element, index }">
                    <div class="virus" @click="removeVirusFromAnswers(answerSecond, index, answerSecondGroup)">
                      <img
                          :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'"
                          :alt="element.imageAlt"
                      />
                      <p
                          v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }"
                      >
                        {{ element.virusId }}
                      </p>
                    </div>
                  </template>
                </draggable>
              </v-col>
              <v-col cols="auto" class="answer-box-number-main">
                <draggable
                    v-model="answerThird"
                    :group="answerThirdGroup"
                    itemKey="virusId"
                    handle=".virus"
                    :sort="false"
                    class="answer-box"
                    :fallbackTolerance="100"
                >
                  <template #item="{ element, index }">
                    <div class="virus" @click="removeVirusFromAnswers(answerThird, index, answerThirdGroup)">
                      <img
                          :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'"
                          :alt="element.imageAlt"
                      />
                      <p
                          v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }"
                      >
                        {{ element.virusId }}
                      </p>
                    </div>
                  </template>
                </draggable>
              </v-col>
              <v-col cols="auto" class="answer-box-number-main">
                <draggable
                    v-model="answerFourth"
                    :group="answerFourthGroup"
                    itemKey="virusId"
                    handle=".virus"
                    :sort="false"
                    class="answer-box"
                    :fallbackTolerance="100"
                >
                  <template #item="{ element, index }">
                    <div class="virus" @click="removeVirusFromAnswers(answerFourth, index, answerFourthGroup)">
                      <img
                          :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'"
                          :alt="element.imageAlt"
                      />
                      <p
                          v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }"
                      >
                        {{ element.virusId }}
                      </p>
                    </div>
                  </template>
                </draggable>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" v-if="answerFirst.length > 0 && answerSecond.length > 0 && answerThird.length > 0 && answerFourth.length > 0 && !(showCorrectAnswerMessage || showWrongAnswerMessage)">
            <button-primary title="Sprawdź" @click="validateAnswers" :icon="require('@/assets/img/icon/play-ico.png')"/>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>

  <dialog-answer :dialog-state="showDialog" @continue="nextQuestion" @retry="retry" :is-success="showCorrectAnswerMessage"/>
</template>

<script>
import draggable from "vuedraggable";
import DialogAnswer from "@/components/Content/DialogAnswerComponent";
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: 'QuizQuestionTypeVirusOrderComponent',
  props: {
    question: Object,
    debug: Boolean,
  },
  components: {
    draggable,
    DialogAnswer,
    ButtonPrimary,
  },
  emits: ['next-question', 'reference-number'],
  expose: ['cleanUp'],
  computed: {
    answerFirst: {
      get() {
        return this.$store.state.answerFirst
      },
      set(value) {
        this.answerFirstGroup.put = false;
        this.$store.state.answerFirst = value;
      }
    },
    answerSecond: {
      get() {
        return this.$store.state.answerSecond
      },
      set(value) {
        this.answerSecondGroup.put = false;
        this.$store.state.answerSecond = value;
      }
    },
    answerThird: {
      get() {
        return this.$store.state.answerThird
      },
      set(value) {
        this.answerThirdGroup.put = false;
        this.$store.state.answerThird = value;
      }
    },
    answerFourth: {
      get() {
        return this.$store.state.answerFourth
      },
      set(value) {
        this.answerFourthGroup.put = false;
        this.$store.state.answerFourth = value;
      }
    },
  },
  data() {
    return {
      positionIteration: 0,
      selectOptions: [...this.question.answers],
      showDialog: false,
      showAnswer: false,
      moveVirusInterval: null,
      retryTimes: 0,
      showWrongAnswerMessage: false,
      showCorrectAnswerMessage: false,
      answerFirstGroup: { name: 'selectedViruses', pull: false, put: true },
      answerSecondGroup: { name: 'selectedViruses', pull: false, put: true },
      answerThirdGroup: { name: 'selectedViruses', pull: false, put: true },
      answerFourthGroup: { name: 'selectedViruses', pull: false, put: true },
    }
  },
  created() {
    this.moveViruses();
    this.moveVirusInterval = setInterval(this.moveViruses, 1000);
    this.$emit('reference-number', this.question.referenceNumber);
    this.$store.state.answerFirst = [];
    this.$store.state.answerSecond = [];
    this.$store.state.answerThird = [];
    this.$store.state.answerFourth = [];
  },
  unmounted() {
    clearInterval(this.moveVirusInterval);
  },
  methods: {
    cleanUp() {
      this.retry();
      this.retryTimes = 0;
    },

    moveViruses() {
      for (let i in this.selectOptions) {
        if (this.selectOptions[i].onMove !== true) {
          let positionIndex = this.positionIteration % this.selectOptions[i].positions.length
          this.selectOptions[i].currentPosition.x = this.selectOptions[i].positions[positionIndex].x;
          this.selectOptions[i].currentPosition.y = this.selectOptions[i].positions[positionIndex].y;
        }
      }

      this.positionIteration += 1;
    },

    startMoveVirusManual(evt) {
      this.selectOptions[evt.oldIndex].onMove = true;
    },

    stopMoveVirusManual(evt) {
      if (evt.to.id === 'select-area') {
        this.selectOptions[evt.oldIndex].onMove = false;
      }
    },

    retry() {
      this.retryTimes += 1;
      this.selectOptions = [...this.question.answers];
      for (let i in this.selectOptions) {
        this.selectOptions[i].onMove = false;
        this.selectOptions[i].incorrectLabel = false;
        this.selectOptions[i].correctGlow = false;
        this.selectOptions[i].incorrectGlow = false;
        this.selectOptions[i].correctLabel = false;
      }
      this.answerFirst = [];
      this.answerSecond = [];
      this.answerThird = [];
      this.answerFourth = [];
      this.answerFirstGroup.put = true;
      this.answerSecondGroup.put = true;
      this.answerThirdGroup.put = true;
      this.answerFourthGroup.put = true;
      this.showDialog = false;
      this.showAnswer = false;
      this.showWrongAnswerMessage = false;
      this.showCorrectAnswerMessage = false;
    },

    nextQuestion() {
      this.$emit('next-question');
    },

    validateAnswers() {
      let isValid = true;
      this.showAnswer = true;

      if (this.answerFirst[0].virusId === this.question.correctAnswerOrder[0]) {
        this.answerFirst[0].correctLabel = true;
      } else {
        this.answerFirst[0].incorrectLabel = true;
        isValid = false;
      }

      if (this.answerSecond[0].virusId === this.question.correctAnswerOrder[1]) {
        this.answerSecond[0].correctLabel = true;
      } else {
        this.answerSecond[0].incorrectLabel = true;
        isValid = false;
      }

      if (this.answerThird[0].virusId === this.question.correctAnswerOrder[2]) {
        this.answerThird[0].correctLabel = true;
      } else {
        this.answerThird[0].incorrectLabel = true;
        isValid = false;
      }

      if (this.answerFourth[0].virusId === this.question.correctAnswerOrder[3]) {
        this.answerFourth[0].correctLabel = true;
      } else {
        this.answerFourth[0].incorrectLabel = true;
        isValid = false;
      }


      if (isValid === false) {
        this.showWrongAnswerMessage = true;
        for (let i in this.selectOptions) {
          if (this.selectOptions[i].isCorrect === true) {
            this.selectOptions[i].correctGlow = true;
            this.selectOptions[i].correctLabel = true;
          } else {
            this.selectOptions[i].incorrectGlow = true;
            this.selectOptions[i].incorrectLabel = true;
          }
        }
      } else {
        this.showCorrectAnswerMessage = true;
        this.showDialog = true;
      }
    },



    removeVirusFromAnswers(answer, answerIndex, group) {
      if (this.showCorrectAnswerMessage || this.showWrongAnswerMessage) {
        return
      }
      answer[answerIndex].onMove = false;
      this.selectOptions.push(answer[answerIndex]);
      answer.splice(answerIndex, 1);
      group.put = true;
    }
  },
};
</script>

<style lang="scss">


.quiz-question-type-virus-order {
  .quiz-question {
    font-size: 1.75rem;
  }

  .virus {
    width: 8em;
    transition: top 1s, left 1s;
    transition-timing-function: linear;
    margin-top: -.615em;

    img {
      width: 100%;
      height: auto;
    }

    p {
      position: absolute;
      top: 60%;
      left: 65%;
      color: $colorDarkGreen;
      background-color: #ffffff;
      border-radius: .25rem;
      border-left: .125rem solid $colorYellow;
      border-right: .125rem solid $colorYellow;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding-top: .125rem;
      font-family: "Invention-Bold";
      width: 2rem;
      text-align: center;
      -webkit-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    }
  }

  #select-area {
    position: relative;
    height: 39vh;
    margin-bottom: 7vh;

    .virus {
      position: absolute;

      &.answer-correct {
        background-color: $colorGreen;
        -webkit-box-shadow: 0 0 40px 10px $colorGreen;
        -moz-box-shadow: 0 0 40px 10px $colorGreen;
        box-shadow: 0 0 40px 10px $colorGreen;
      }

      &.answer-incorrect {
        background-color: $colorRed;
        -webkit-box-shadow: 0 0 40px 10px $colorRed;
        -moz-box-shadow: 0 0 40px 10px $colorRed;
        box-shadow: 0 0 40px 10px $colorRed;
      }

      p {
        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: .125rem solid $colorForestGreen;
          border-right: .125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: .125rem solid $colorDarkRed;
          border-right: .125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-ghost {
      opacity: 0;
    }

    .sortable-drag {
      opacity: 1 !important;
    }
  }

  .answer-box {
    position: relative;
    margin: .125rem 1.75rem;
    width: 8.5rem;
    height: 7rem;
    border-radius: 1.75rem;
    border-left: .25rem solid $colorYellow;
    background-color: white;


    &__desc {
      position: absolute;
      top: 65.5vh;
      font-family: "Invention-Italic";
      font-size: 1rem;
      color: $colorYellow;
    }

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 124%;
      top: 0;
      right: -22%;
      border-radius: 1.5rem;
      background-color: $colorYellow;
      z-index: -1;
      -webkit-box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
    }

    &-number {
      position: relative;

      &::after {
        position: absolute;
        height: auto;
        width: auto;
        top: 1.5rem;
        right: -1.25rem;
        z-index: 1;
        font-family: "Invention-Bold";
        font-size: 1.25rem;
        color: #00867c;
        }

        &:first-child::after {
          content: "1";
        }

        &:nth-child(2)::after {
          content: "2";
        }

        &:nth-child(3)::after {
          content: "3";
        }

        &:nth-child(4)::after {
          content: "4";
        }
    }
    
    &-number-main {
      position: relative;

      &::after {
        position: absolute;
          height: auto;
          width: auto;
          top: 1.5rem;
          right: 1.25rem;
          z-index: 1;
          font-family: "Invention-Bold";
          font-size: 1.25rem;
          color: #00867c;
        }

        &:first-child::after {
          content: "1";
        }

        &:nth-child(2)::after {
          content: "2";
        }

        &:nth-child(3)::after {
          content: "3";
        }

        &:nth-child(4)::after {
          content: "4";
        }
    }
    

    .virus {
      float: left;
      position: relative;

      p {
        color: $colorDarkGreen;

        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: .125rem solid $colorForestGreen;
          border-right: .125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: .125rem solid $colorDarkRed;
          border-right: .125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-drag, .sortable-ghost {
      opacity: 0 !important;
    }


  }


  &__answer-box-section {
      position: relative;
      z-index: 9;
      margin-top: 5vh !important;
      

    }


  &__answer-table {
    height: 60vh;
    width: auto;
    background-color: #ffffff;
    padding: 4rem 4.5rem 2.5rem;
    border-radius: 2rem;

    &-box {
      height: 60vh;
      padding: 0 .375rem !important;
      margin: -5vh auto 0;
      border-radius: 2rem;
      background-color: $colorYellow;
      z-index: -1;
      -webkit-box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px 6px rgba(66, 68, 90, 1);
    }
  }


}
</style>

