// main.js
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueFullscreen from 'vue-fullscreen'
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})

const metaManager = createMetaManager()

createApp(App)
    .use(store)
    .use(router)
    .use(vuetify)
    .use(VueFullscreen)
    .use(metaManager)
    .use(metaPlugin)
    .mount('#app');
