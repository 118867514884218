import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ForOForView from '@/views/404View.vue'
import QuizView from '@/views/QuizView.vue'
import QuizSummaryView from '@/views/QuizSummaryView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/404',
        name: 'four_o_four',
        component: ForOForView,
    },
    {
        path: '/quiz',
        name: 'quiz',
        component: QuizView,
    },
    {
        path: '/quiz/summary',
        name: 'quiz_summary',
        component: QuizSummaryView,
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
