<template>
  <v-row no-gutters class="d-flex flex-wrap align-content-space-around text-center">
    <v-col cols="12" class="text-center">
      <div class="home-view__logo-bgr">
        <img :src="require('@/assets/img/main/gardasil-logo.png')" class="home-view__logo"/>
      </div>
      <h2 class="home-view__title">
        Sprawdź swoją wiedzę
        <br/>
        na temat wirusa
        <span>HPV</span>
      </h2>
      <router-link :to="{ name: 'quiz' }">
        <div class="home-view__start-box">
          <button class="home-view__start-box-button">
            Rozpocznij quiz
            <img :src="require('@/assets/img/icon/play-ico.png')"/>
          </button>
        </div>
      </router-link>
    </v-col>
  </v-row>
  <div class="home-view__reset-quiz-stats" :style="{ opacity: opacityForQuizInformation }" @click="resetQuizStats()">
    Quiz reset done
  </div>
</template>

<script>
export default {
  name: "HomeView",
  emits: ["accept-number"],
  data() {
    return {
      opacityForQuizInformation: 0,
    }
  },
  created() {
    this.$emit("accept-number", "PL-GSL-00160 wyd. 11.2023");
  },
  methods: {
    resetQuizStats() {
      this.opacityForQuizInformation = 1;
      localStorage.removeItem("quiz-record-time")
      setTimeout(() => {
        this.opacityForQuizInformation = 0;
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
.home-view {
  &__logo {
    background-color: #ffffff;
    width: 50vw;
    height: auto;
    padding: 2vh 4vw;
    border-radius: 2rem;

    &-bgr {
      background-color: $colorYellow;
      border-radius: 2rem;
      width: 54vw;
      text-align: center;
      margin: 8vh auto 6vh;
    }
  }

  &__reset-quiz-stats {
    position: absolute;
    left: 25vw;
    top: 12vh;
    width: 50vw;
    height: 24vh;
    padding: 16vh 0 0;
    z-index: 10;
    color: $colorYellow;
    opacity: 0;
    transition: opacity 1s;
    font-size: 1rem;
    text-align: center;
    vertical-align: middle;
  }

  &__title {
    font-family: "Invention-Bold";
    font-size: 1.75rem;
    color: #ffffff;
    text-shadow: 0px 20px 41px #0b0b0e;
    text-transform: uppercase;

    span {
      color: $colorYellow;
    }
  }

  &__start-box {
    width: 25vw;
    height: 40vh;
    background-image: url("~@/assets/img/main/virus-start.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin: 1vh auto;

    &-button {
      position: absolute;
      right: -25vw;
      top: 33%;
      font-family: "Invention-Bold";
      font-size: 1.75rem;
      line-height: 1px;
      height: 3rem;
      width: auto;
      color: $colorDarkGreen;
      text-transform: uppercase;
      background-color: #ffffff;
      padding: 0.25rem 1rem 0 1rem;
      border-radius: 0.5rem;
      border-left: 0.25rem solid $colorYellow;
      border-right: 0.25rem solid $colorYellow;
      -webkit-box-shadow: 0px 0px 30px 7px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 30px 7px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 30px 7px rgba(66, 68, 90, 1);
      z-index: 9;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        height: auto;
        width: 1.75rem;
        margin-left: 0.5rem;
        margin-top: -0.25rem;
      }
    }
  }
}
</style>
