<template>
  <section class="quiz-question-type-virus-syringe">
    <v-row v-if="endScreen" class="quiz-question__box align-content-start">
      <v-col cols="12" v-html="question.titleSecond" class="quiz-question"></v-col>
      <v-col cols="12">
        <v-row class="quiz-question-type-virus-syringe__main-card justify-center align-content-center">
          <v-col cols="4" class="quiz-question-type-virus-syringe__main-card-gentype">9vHPV</v-col>
          <v-col cols="5" class="quiz-question-type-virus-syringe__main-card-gentype-viruses">
            <div v-for="(element, elementKey) in validViruses" class="virus" :key="elementKey">
              <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
              <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
                {{ element.virusId }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <button-primary title="Dalej" @click="nextQuestion" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
    </v-row>

    <v-row no-gutters class="quiz-question__box quiz-question__info align-content-start" v-if="!endScreen">
      <v-col cols="12" v-html="question.title" class="quiz-question"></v-col>
      <v-col cols="12" v-if="answers.length < 9" class="quiz-question__info__regular">Przeciągnij właściwe odpowiedzi do strzykawki</v-col>
      <v-col cols="12" v-if="showCorrectAnswerMessage" class="quiz-question__info__success text-uppercase">poprawna odpowiedź</v-col>
      <v-col v-if="showWrongAnswerMessage" class="quiz-question__info__danger text-uppercase">błędna odpowiedź</v-col>
    </v-row>
    <v-row no-gutters v-if="!endScreen">
      <v-col cols="12">
        <draggable
            v-model="selectOptions"
            :group="{ name: 'selectedViruses', pull: true, put: false }"
            itemKey="virusId"
            handle=".virus"
            :sort="false"
            id="select-area"
            :onStart="startMoveVirusManual"
            :onEnd="stopMoveVirusManual"
            :disabled="answers.length >= 9">
          <template #item="{ element, index }">
            <div
                class="virus"
                :style="{ left: element.currentPosition.x, top: element.currentPosition.y }"
                :key="index"
                v-bind:class="{ 'answer-correct': element.correctGlow, 'answer-incorrect': element.incorrectGlow }">
              <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
              <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
                {{ element.virusId }}
              </p>
            </div>
          </template>
        </draggable>
      </v-col>
      <v-col cols="12">
        <div class="syringe__instruction" v-if="!(showCorrectAnswerMessage || showWrongAnswerMessage)">Kliknij w wirusa, aby usunąć go z pola wyboru <p class="syringe__instruction-counter"><span>{{ answers.length }}</span> / {{ question.validViruses.length }}</p></div>
        <div class="syringe">
          <div class="syringe__barrel">
            <draggable v-model="answers" :group="{ name: 'selectedViruses', pull: false, put: true }" itemKey="virusId" handle=".virus" :sort="false" id="answer-box"
                       :fallbackTolerance="100">
              <template #item="{ element, index }">
                <div class="virus" @click="removeVirusFromAnswers(index)">
                  <img :src="'/img/virus/' + question.imgPrefixPath + '/' + element.virusId + '.png'" :alt="element.imageAlt"/>
                  <p v-bind:class="{ 'answer-correct': element.correctLabel, 'answer-incorrect': element.incorrectLabel }">
                    {{ element.virusId }}
                  </p>
                </div>
              </template>
            </draggable>
          </div>
          <div class="syringe__plunger-custom" :style="{ left: this.plungerPosition, width: this.plungerWidth }">
          </div>
        </div>
      </v-col>
      <v-col cols="12" v-if="answers.length >= 9 && !(showCorrectAnswerMessage || showWrongAnswerMessage)">
        <button-primary title="Sprawdź" @click="validateAnswers" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes >= 1">
        <button-primary title="Dalej" @click="showEndScreen()" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
      <v-col cols="12" v-if="showWrongAnswerMessage && retryTimes === 0">
        <button-primary title="Dalej" @click="showDialog = true" :icon="require('@/assets/img/icon/play-ico.png')"/>
      </v-col>
    </v-row>
    <dialog-answer :dialog-state="showDialog" @continue="showEndScreen()" @retry="retry" :is-success="showCorrectAnswerMessage"/>
  </section>
</template>

<script>
import draggable from "vuedraggable";
import DialogAnswer from "@/components/Content/DialogAnswerComponent";
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: "QuizQuestionTypeVirusSyringeComponent",
  props: {
    debug: Boolean,
    question: Object,
  },
  components: {
    draggable,
    DialogAnswer,
    ButtonPrimary,
  },
  emits: ["next-question", "reference-number"],
  expose: ["cleanUp"],
  data() {
    return {
      positionIteration: 0,
      selectOptions: [...this.question.answers],
      showDialog: false,
      moveVirusInterval: null,
      retryTimes: 0,
      showWrongAnswerMessage: false,
      showCorrectAnswerMessage: false,
      plungerPosition: "",
      plungerWidth: "",
      endScreen: false,
      validViruses: [],
    };
  },
  created() {
    this.validViruses = [];
    for (let i in this.selectOptions) {
      if (this.question.validViruses.includes(this.selectOptions[i].virusId)) {
        this.validViruses = this.validViruses.concat(this.selectOptions[i]);
      }
    }
    this.moveViruses();
    this.moveVirusInterval = setInterval(this.moveViruses, 1000);
    this.$emit("reference-number", this.question.referenceNumber);
    this.answers = [];
  },
  unmounted() {
    clearInterval(this.moveVirusInterval);
  },
  computed: {
    answers: {
      get() {
        return this.$store.state.answers;
      },
      set(value) {
        this.$store.state.answers = value;
        this.plungerPositionSet();
      },
    },
  },
  methods: {
    cleanUp() {
      this.retry();
      this.retryTimes = 0;
    },

    moveViruses() {
      for (let i in this.selectOptions) {
        if (this.selectOptions[i].onMove !== true) {
          let positionIndex = this.positionIteration % this.selectOptions[i].positions.length;
          this.selectOptions[i].currentPosition.x = this.selectOptions[i].positions[positionIndex].x;
          this.selectOptions[i].currentPosition.y = this.selectOptions[i].positions[positionIndex].y;
        }
      }

      this.positionIteration += 1;
    },

    startMoveVirusManual(evt) {
      this.selectOptions[evt.oldIndex].onMove = true;
    },

    stopMoveVirusManual(evt) {
      if (evt.to.id === "select-area") {
        this.selectOptions[evt.oldIndex].onMove = false;
      }
    },

    plungerPositionSet() {
      switch (this.answers.length) {
        case 0:
        case 1:
        case 2:
        case 3:
          this.plungerWidth = "40vw";
          this.plungerPosition = "55%";
          break;
        case 4:
          this.plungerWidth = "37vw";
          this.plungerPosition = "63%";
          break;
        case 5:
          this.plungerWidth = "34vw";
          this.plungerPosition = "71%";
          break;
        case 6:
          this.plungerWidth = "31vw";
          this.plungerPosition = "79%";
          break;
        case 7:
          this.plungerWidth = "28vw";
          this.plungerPosition = "87%";
          break;
        case 8:
        case 9:
          this.plungerWidth = "28vw";
          this.plungerPosition = "95%";
          break;
      }
    },

    nextQuestion() {
      this.$emit("next-question");
    },

    showEndScreen() {
      this.showDialog = false;
      this.endScreen = true;
    },

    retry() {
      this.retryTimes += 1;
      this.selectOptions = [...this.question.answers];
      for (let i in this.selectOptions) {
        this.selectOptions[i].onMove = false;
        this.selectOptions[i].incorrectLabel = false;
        this.selectOptions[i].correctGlow = false;
        this.selectOptions[i].incorrectGlow = false;
        this.selectOptions[i].correctLabel = false;
      }
      this.answers = [];
      this.showDialog = false;
      this.showWrongAnswerMessage = false;
      this.showCorrectAnswerMessage = false;
      this.endScreen = false;
    },

    validateAnswers() {
      let isValid = true;
      for (let i in this.answers) {
        if (this.answers[i].isCorrect === true) {
          this.answers[i].correctLabel = true;
        } else {
          isValid = false;
          this.answers[i].incorrectLabel = true;
        }
      }

      if (isValid === false) {
        this.showWrongAnswerMessage = true;
        for (let i in this.selectOptions) {
          if (this.selectOptions[i].isCorrect === true) {
            this.selectOptions[i].correctGlow = true;
            this.selectOptions[i].correctLabel = true;
          } else {
            this.selectOptions[i].incorrectGlow = true;
            this.selectOptions[i].incorrectLabel = true;
          }
        }
      } else {
        this.showCorrectAnswerMessage = true;
        this.showDialog = true;
      }
    },

    removeVirusFromAnswers(answerIndex) {
      if (this.showCorrectAnswerMessage || this.showWrongAnswerMessage) {
        return;
      }
      this.answers[answerIndex].onMove = false;
      this.selectOptions.push(this.answers[answerIndex]);
      this.answers.splice(answerIndex, 1);
      this.plungerPositionSet();
    },
  },
};
</script>

<style lang="scss">


.quiz-question-type-virus-syringe {
  .virus {
    position: relative;
    width: 8em;
    transition: top 1s, left 1s;
    transition-timing-function: linear;

    img {
      width: 100%;
      height: auto;
    }

    p {
      position: absolute;
      top: 60%;
      left: 65%;
      color: $colorDarkGreen;
      background-color: #ffffff;
      border-radius: 0.25rem;
      border-left: 0.125rem solid $colorYellow;
      border-right: 0.125rem solid $colorYellow;
      font-size: 0.75rem;
      line-height: 1.25rem;
      padding-top: .125rem;
      font-family: "Invention-Bold";
      width: 2rem;
      text-align: center;
      -webkit-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 55px -12px rgba(66, 68, 90, 1);
    }
  }

  #select-area {
    position: relative;
    height: 49vh;
    padding-bottom: 7vh;

    .virus {
      position: absolute;

      &.answer-correct {
        -webkit-filter: drop-shadow(0 0 2rem $colorGreen);
        filter: drop-shadow(0 0 1rem $colorGreen);
        background: radial-gradient(circle at center, $colorGreen 16%, transparent 56%);
        color: #ffffff;
      }

      &.answer-incorrect {
        -webkit-filter: drop-shadow(0 0 2rem $colorRed);
        filter: drop-shadow(0 0 1rem $colorRed);
        background: radial-gradient(circle at center, $colorRed 16%, transparent 56%);
        color: #ffffff;
      }

      p {
        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: 0.125rem solid $colorForestGreen;
          border-right: 0.125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: 0.125rem solid $colorDarkRed;
          border-right: 0.125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-ghost {
      opacity: 0;
    }

    .sortable-drag {
      opacity: 1 !important;
    }
  }



  

  .syringe {
    position: relative;
    width: 70vw;
    height: 13vh;

    &__instruction {
      position: absolute;
      margin-right: -50%;
      transform: translate(-50%, 0);
      display: flex;
      justify-content: center;
      align-items: flex-end;
      font-family: "Invention-Italic";
      font-size: 1rem;
      line-height: 1rem;
      color: #e6e32b;
      left: 50%;
      top: 69vh;

      &-counter {
      font-family: "Invention-Bold";
      font-size: 1.125rem;
      color: $colorYellow;
      min-width: 5rem;

        span {
          font-size: 2rem;
        }
      }
    }

    &__barrel {
      width: 70vw;
      height: 13vh;
      position: absolute;
      background-image: url("~@/assets/quiz/syringe/syringe-barrel.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 10;
    }

    &__plunger-custom {
      background-color: #99b1dc;
      height: 5vh;
      position: absolute;
      z-index: -1;
      top: 50%;
      transform: translate(0, -50%);
      transition: left 0.5s, width 0.5s;

      &::before {
        content: "";
        position: absolute;
        width: 3vw;
        height: 11vh;
        background-color: #434e80;
        left: -5px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &::after {
        content: "";
        position: absolute;
        width: 1.5vw;
        height: 13vh;
        background-color: #839fd2;
        right: 0px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &__main-card {
    height: 75vh;

    &-gentype {
      font-family: "Invention-Bold";
      font-size: 3.5rem;
      margin-right: 0.75rem;
      padding-top: 10% !important;
      color: $colorYellow;
      border-right: 0.25rem solid $colorYellow;
    }

    &-gentype-viruses {
      border-left: 0.25rem solid $colorYellow;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .virus {
        &:nth-child(1) {
          margin-left: -6%;
        }

        &:nth-child(4) {
          margin-left: 22%;
        }

        &:nth-child(7) {
          margin-left: -6%;
        }
      }
    }
  }

  #answer-box {
    margin-left: 16.5vw;
    width: 75%;
    height: 85%;
    background-color: rgba(255, 255, 255, 0.07);
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    .virus {
      float: left;
      position: relative;
      max-width: 10vh;
      padding-top: 0.125rem;

      p {
        color: $colorDarkGreen;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);

        &.answer-correct {
          background-color: $colorGreen;
          color: #ffffff;
          border-left: 0.125rem solid $colorForestGreen;
          border-right: 0.125rem solid $colorForestGreen;
        }

        &.answer-incorrect {
          background-color: $colorRed;
          color: #ffffff;
          border-left: 0.125rem solid $colorDarkRed;
          border-right: 0.125rem solid $colorDarkRed;
        }
      }
    }

    .sortable-drag,
    .sortable-ghost {
      opacity: 0 !important;
    }
  }
}
</style>
