<template>
    <metainfo></metainfo>
    <div class="full-screen" @click="fullScreen"><img :src="fullScreenImagePath" /></div>
    <div class="accept-number">{{ acceptNumber }}</div>
    <div class="logo">
        <a href="https://www.msd.com/our-commitment-to-accessibility-for-all/"><p>Accessibility</p></a>
        <a href="https://www.essentialaccessibility.com/msd">
            <img :src="require('@/assets/img/main/essential-icon.png')" class="essential-ico" />
        </a>
        <router-link :to="{ name: 'home' }">
            <img :src="require('@/assets/img/main/msd-logo.png')"/>
        </router-link>
    </div>
    <div class="content">
        <v-row no-gutters class="content-area">
            <v-col cols="12" class="content-box d-flex flex-column h-100">
                <router-view @accept-number="handleAcceptNumber" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
window.oncontextmenu = function (event) {
    event.preventDefault();
    event.stopPropagation();
    return false;
};

export default {
    components: {},
    data() {
        return {
            logoPath: "",
            fullScreenImagePath: require("@/assets/img/icon/full-screen-open.png"),
            isFullScreenMode: true,
            acceptNumber: "",
        };
    },
    methods: {
        handleAcceptNumber(value) {
            this.acceptNumber = value;
        },
        fullScreen() {
            this.$fullscreen.toggle();
            if (this.isFullScreenMode) {
                this.isFullScreenMode = false;
                this.fullScreenImagePath = require("@/assets/img/icon/full-screen-close.png");
            } else {
                this.isFullScreenMode = true;
                this.fullScreenImagePath = require("@/assets/img/icon/full-screen-open.png");
            }
        },
    },
    metaInfo() {
        return {
            title: "HPV",
            meta: [
                { name: "msapplication-TileColor", content: "#00857c" },
                { name: "theme-color", content: "#ffffff" },
            ],
            link: [
                { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
                { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
                { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
                { rel: "manifest", href: "/site.webmanifest" },
                { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: '"#00857c' },
            ],
        };
    },
};
</script>

<style lang="scss">
@font-face {
    font-family: "Invention-Regular";
    src: url("~@/assets/font/Invention-Regular.woff2") format("woff2"), url("~@/assets/font/Invention-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Invention-Bold";
    src: url("~@/assets/font/Invention-Bold.woff2") format("woff2"), url("~@/assets/font/Invention-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: "Invention-ExtraBold";
    src: url("~@/assets/font/Invention-ExtraBold.woff2") format("woff2"), url("~@/assets/font/Invention-ExtraBold.ttf") format("truetype");
    font-weight: bold;
    font-style: bold;
}

@font-face {
    font-family: "Invention-Italic";
    src: url("~@/assets/font/Invention-Italic.woff2") format("woff2"), url("~@/assets/font/Invention-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}

* {
    box-sizing: border-box;
}

:not(:root):fullscreen::backdrop {
    background: none;
}

html,
body,
#app {
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Invention-Regular", sans-serif;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

html {
    font-size: 16px !important;
}

@media only screen and (max-width: 1279px) {
    html {
        font-size: 12px !important;
    }
}

@media only screen and (min-width: 1280px) {
    html {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 1920px) {
    html {
        font-size: 28px !important;
    }
}

@media only screen and (min-width: 2560px) {
    html {
        font-size: 38px !important;
    }
}

@media only screen and (min-width: 3440px) {
    html {
        font-size: 48px !important;
    }
}

body {
    background-image: url("~@/assets/img/main/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: white;
}

a {
    text-decoration: none;
    color: white;
}

img {
    background-color: transparent;
}

strong {
    font-family: "Invention-Bold";
}

i {
    font-family: "Invention-Italic";
}

.accept-number {
    position: absolute;
    transform: rotate(-90deg);
    right: -3rem;
    top: 50%;
    color: #256161;
    font-family: "Invention-Regular";
    font-size: 0.625rem;
}

.content {
    height: 100%;

    .content-area {
        height: 100%;
        width: 100%;
        margin: 0 !important;
        padding: 2vh 2vw;
    }
}

.logo {
    position: absolute;
    right: 2vw;
    bottom: 2vh;
    z-index: 9999999;
    display: flex;
    align-items: center;
    font-size: 0.5rem;
    font-family: "Invention-Regular", sans-serif;
    color: #ffffff;


    img {
        width: auto;
        height: 6.5vh;
        display: block;
    }

    .essential-ico {
        width: auto;
        height: 3.5vh;
        display: block;
        margin: 0 1vw;
    }
}



.full-screen {
    position: absolute;
    top: 2vh;
    right: 2vw;
    z-index: 99999;

    img {
        height: 3em;
        width: auto;
    }
}

.quiz-question {
    font-family: "Invention-Bold";
    font-size: 2rem;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
    text-shadow: -1px 18px 49px rgba(47, 47, 49, 1);

    &__box {
        min-height: 23vh;
    }

    span {
        color: $colorYellow;
    }

    &__info {
        font-family: "Invention-Italic";
        font-size: 1.5em;
        height: 5vh;

        &__regular {
            color: $colorYellow;
        }
        &__danger {
            color: #f41818;

            span {
                font-family: "Invention-Regular";
                color: $colorYellow;
                margin-left: 1rem;
            }
        }
        &__success {
            color: $colorLightBlue;
        }
    }
}

// Scrollbar
/* width */
::-webkit-scrollbar {
    width: 0.25em;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $colorGreen;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $colorGreen;
}

</style>
