<template>
  <section class="quiz-component">
    <div id="reference-number">{{ referenceNumber }}</div>
    <div class="quiz-component__question_number">
      <span class="quiz-component__question_number--upper">{{ questionIndex + 1 }}</span>
      / {{ currentQuiz.length }}
    </div>
    <div v-if="currentQuestion" class="w-100 text-center">
      <component
          :is="currentQuestion.questionType"
          :question="currentQuestion"
          :key="currentQuestion.questionId"
          @next-question="nextQuestion"
          @reference-number="setReferenceNumber"
          ref="questionTypeComponent"
          :debug="debug"
      />
      <button v-if="debug && questionIndex + 1 <= currentQuiz.length" style="position: absolute; bottom: 10px; z-index: 100" @click="nextQuestion">Następne</button>
    </div>
    <div v-if="!currentQuestion">
      <p>Loading...</p>
    </div>
  </section>
</template>

<script>
import QuizData from "@/components/Quiz/quiz.json";
import QuizQuestionTypePersonComponent from "@/components/Quiz/QuizQuestionType/QuizQuestionTypePersonComponent";
import QuizQuestionTypeVirusOrderComponent from "@/components/Quiz/QuizQuestionType/QuizQuestionTypeVirusOrderComponent";
import QuizQuestionTypeVirusSelectComponent from "@/components/Quiz/QuizQuestionType/QuizQuestionTypeVirusSelectComponent";
import QuizQuestionTypeVirusSyringeComponent from "@/components/Quiz/QuizQuestionType/QuizQuestionTypeVirusSyringeComponent";
import QuizQuestionTypeVirusMemoComponent from "@/components/Quiz/QuizQuestionType/QuizQuestionTypeVirusMemoComponent";

export default {
  name: "QuizComponent",
  props: {
    quizId: Number,
    debug: Boolean,
  },
  components: {
    QuizQuestionTypePersonComponent,
    QuizQuestionTypeVirusOrderComponent,
    QuizQuestionTypeVirusSelectComponent,
    QuizQuestionTypeVirusSyringeComponent,
    QuizQuestionTypeVirusMemoComponent,
  },
  expose: ["nextQuestion"],
  data() {
    return {
      loaded: false,
      questionIndex: 0,
      currentQuiz: null,
      currentQuestion: null,
      referenceNumber: "zzzzz00000",
    };
  },
  created() {
    this.loadQuizData();
  },
  beforeUnmount() {
    this.resetQuiz();
  },
  methods: {
    loadQuizData() {
      const quiz = QuizData.find((quiz) => quiz.quizId === this.quizId);
      this.currentQuiz = quiz["questions"];
      this.currentQuestion = this.currentQuiz[this.questionIndex];
    },

    nextQuestion() {
      this.$refs.questionTypeComponent.cleanUp();
      if (this.questionIndex + 1 < this.currentQuiz.length) {
        this.questionIndex++;
        this.currentQuestion = this.currentQuiz[this.questionIndex];
      } else {
        this.$router.push({name: "quiz_summary"});
      }
    },

    resetQuiz() {
      this.questionIndex = 0;
      this.currentQuiz = null;
      this.currentQuestion = null;
    },

    setReferenceNumber(value) {
      this.referenceNumber = value;
    },
  },
};
</script>

<style lang="scss">
.quiz-component {
  #reference-number {
    position: absolute;
    left: 1vw;
    bottom: 1vh;
    font-size: 0.5rem;
    color: #abdeda;
    z-index: 10;
  }

  &__question_number {
    position: absolute;
    left: 2vw;
    top: 2vh;
    background-image: url("~@/assets/img/main/progress-circle.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 6em;
    height: 6em;
    padding-left: 1.5em;
    line-height: 5.5em;
  }

  &__question_number--upper {
    font-size: 1.5em;
  }
}
</style>
