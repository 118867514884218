<template>
  <v-dialog v-model="internalDialogState">
    <div v-bind:class="{'dialog__wrong-answer--light-red': !isSuccess, 'dialog__success-answer--light-green': isSuccess}">
      <v-col cols="auto" v-bind:class="{'dialog__wrong-answer': !isSuccess, 'dialog__success-answer': isSuccess}" class="text-uppercase text-center">
        <p v-if="!isSuccess">Błędna&nbsp;&nbsp;Odpowiedź</p>
        <div v-if="isSuccess">Poprawna odpowiedź <p class="dialog__success-answer-title">Gratulacje</p></div>
        <p class="dialog__wrong-answer-title" v-if="!isSuccess">Chcesz&nbsp;&nbsp;powtórzyć&nbsp;&nbsp;pytanie? <img :src="require('@/assets/img/icon/try-again-ico.png')"></p>
        <v-row v-if="!isSuccess">
          <v-col cols="6" class="d-flex flex-column">
            TAK
            <button-primary title="Powtórz" @click="emitRetry" :icon="require('@/assets/img/icon/try-again-ico-green.png')" class="dialog__wrong-answer-button"/>
          </v-col>
          <v-col cols="6">
            NIE
            <button-primary title="Przejdź Dalej" @click="emitContinue" :icon="require('@/assets/img/icon/play-ico.png')" class="dialog__wrong-answer-button"/>
          </v-col>
        </v-row>
        <img :src="require('@/assets/img/main/virus-wrong-answer.png')" class="dialog__wrong-answer-virus">
        <v-row v-if="isSuccess">
          <button-primary title="Przejdź dalej" @click="emitContinue" :icon="require('@/assets/img/icon/play-ico.png')" class="dialog__success-answer-button"/>
        </v-row>
     </v-col>
    </div>
  </v-dialog>
</template>

<script>
import ButtonPrimary from "@/components/Elements/ButtonPrimaryComponent";

export default {
  name: 'DialogAnswerComponent',
  components: {
    ButtonPrimary
  },
  props: {
    dialogState: Boolean,
    isSuccess: Boolean,
  },
  emits: ['state','continue', 'retry'],
  methods: {
    emitRetry() {
      this.$emit('retry');
    },
    emitContinue() {
      this.$emit('continue');
    },
  },
  computed: {
    internalDialogState: {
      get() {
        return this.dialogState;
      },
      set(newValue) {
        this.$emit('state', newValue);
      },
    },
  },
}
</script>

<style lang="scss">
.v-overlay-container .v-overlay__scrim {
  background: none;
  opacity: .95;
  background-image: url("~@/assets/img/main/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dialog__wrong-answer {
  position: relative;
  padding: 3rem 7rem 4rem !important;
  background-color: #dc3333;
  color: #ffffff;
  font-family: "Invention-Bold";
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-radius: .5rem;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 50px 0px rgb(173 62 62);
  -moz-box-shadow: 0px 0px 50px 0px rgb(173 62 62);
  box-shadow: 0px 0px 50px 0px rgb(173 62 62);
  
  &--light-red {
    background-color: #ff6666;
    width: 63vw;
    height: auto;
    margin: 0 auto;
    padding: 0 .5rem;
    border-radius: .5rem;
    margin-top: -8vh
  }

  &-title {
    font-family: "Invention-Bold";
    font-size: 2rem;
    padding: 2rem 0 2.5rem;
    margin-top: 2rem;
    border-top: 2px solid #ffffff;

    img {
      vertical-align: middle;
      height: 3rem;
      width: auto;
      padding-bottom: .5rem;
      margin-left: 1rem;
    }
  }

  hr {
    width: 80%;
    margin: 1rem auto;
  }

  &-button {
    position: static;
    transform: none;
    width: 15rem;
    margin: .75rem auto 0;
    }

  &-virus {
    width: 17vw;
    height: auto;
    position: absolute;
    z-index: 99;
    right: -10vw;
    bottom: -15vh;
  }
}

.dialog__success-answer {
  position: relative;
  padding: 3rem 7rem 4rem !important;
  background-color: #16684a;
  color: #ffffff;
  font-family: "Invention-Bold";
  font-size: 1.5rem;
  line-height: 2.5rem;
  border-radius: .5rem;
  z-index: 9;
  -webkit-box-shadow: 0px 0px 167px 9px rgb(28 146 66);
  -moz-box-shadow: 0px 0px 167px 9px rgb(28 146 66);
  box-shadow: 0px 0px 167px 9px rgb(28 146 66);

  &--light-green {
    background-color: #4cd302;
    width: 63vw;
    height: auto;
    margin: 0 auto;
    padding: 0 .5rem;
    border-radius: .5rem;
    margin-top: -8vh
  }

  &-title {
    font-family: "Invention-Bold";
    font-size: 2rem;
    padding: 2rem 0 2.5rem;
    margin-top: 2rem;
    border-top: 2px solid #ffffff;
  }

  &-button {
    position: static;
    transform: none;
    width: 15rem;
    margin: .75rem auto 0;
    }
}
  
</style>
