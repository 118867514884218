<template>
    <div class="end-quiz" @click="endQuiz">
        Zakończ
        <br />
        Quiz
    </div>
    <v-row no-gutters>
        <v-col cols="12">
            <Quiz :quizId="1" ref="renderedQuizComponent" :debug="false" />
        </v-col>
    </v-row>
</template>

<script>
import Quiz from "@/components/Quiz/QuizComponent";

export default {
    name: "QuizView",
    components: {
        Quiz,
    },
    emits: ["accept-number"],
    created() {
        this.$emit("accept-number", "PL-GSL-00160 wyd. 11.2023");
        localStorage.setItem("quiz-start-time", new Date().getTime());
    },
    methods: {
        endQuiz() {
            this.$refs.renderedQuizComponent.nextQuestion();
            this.$router.push({ name: "home" });
        },
    },
};
</script>

<style lang="scss">
.end-quiz {
    position: absolute;
    bottom: 4vh;
    left: 1vw;
    font-size: 0.5em;
    font-family: "Invention-Bold";
    text-transform: uppercase;
    text-align: center;
    color: $colorDarkGreen;
    padding: 1.15em 0.25em 1em;
    background-color: #ffffff;
    border-radius: 0.5em;
    z-index: 9;
}


</style>
